<template>
  <div>
    <b-card ref="filterCard" title="Filtros" class="user-list-filters mb-8">
      <b-row>
        <b-col md="4" sm="12">
          <b-form-group label="Proyectos" label-for="project">
            <v-select
              v-model="filtersForm.project"
              label="name"
              placeholder="Selecciona un Proyecto"
              :options="projects"
              :reduce="projects => projects.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Estado Rukan" label-for="project">
            <v-select
              v-model="filtersForm.rukan"
              label="name"
              placeholder="Selecciona un estado"
              :options="rukanState"
              :reduce="projects => projects.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Estado Pre-aprobado" label-for="project">
            <v-select
              v-model="filtersForm.pre_approval"
              label="name"
              placeholder="Selecciona un estado"
              :options="preApprovedState"
              :reduce="preApprovedState => preApprovedState.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="General" label-for="general">
            <b-form-input id="general"
            v-model="filtersForm.general"
            name="general"
            placeholder="Ingresa una palabra clave"/>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Fecha de Inicio" label-for="date_ini">
            <b-form-datepicker
              locale="es"
              id="date_ini"
              :max="filtersForm.end"
              placeholder="Fecha"
              v-model="filtersForm.start"/>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Fecha de Termino" label-for="date_end">
            <b-form-datepicker
              locale="es"
              id="date_end"
              :min="filtersForm.start"
              placeholder="Fecha"
              v-model="filtersForm.end"/>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      
      <div class="d-flex align-items-end justify-content-end">
        <b-button-group class="mx-2">
          <b-button
            :pressed="view === 'list'"
            :variant="view === 'list' ? 'primary' : 'outline-primary'"
            @click="view = 'list'">
            <feather-icon
              icon="ListIcon"
              class="mr-50"/>
          </b-button>
          <b-button
            :pressed="view === 'card'"
            :variant="view === 'card' ? 'primary' : 'outline-primary'"
            @click="view = 'card'">
            <feather-icon
              icon="CreditCardIcon"
              class="mr-50"/>
          </b-button>
        </b-button-group>
        <b-overlay
          :show="overlayForm"
          opacity="0.5"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          @hidden="_onHidden()" >
          <b-button
            ref="button"
            variant="primary"
            :disabled="overlayForm"
            @click.prevent="_exportBusiness()" v-if="permissions_visible.download_business">
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"/>
            <span class="align-middle">
              Descargar Negocios
            </span>
          </b-button>
        </b-overlay>
      </div>
      <b-row class="justify-center d-md-none my-2" align="center">
        <b-col cols="12">
          <b-dropdown variant="primary" id="dropdown-1" text="Nivel de criticidad" class="m-md-2">
            <b-dropdown-item><p class="text-lg flex"><feather-icon
              icon="UserIcon"
              size="30"
              color="red"
              class="mr-50"/>Muy alto</p></b-dropdown-item>
            <b-dropdown-item><p class="text-lg flex"><feather-icon
              icon="UserIcon"
              size="30"
              color="orange"
              class="mr-50"/>Alto</p></b-dropdown-item>
            <b-dropdown-item><p class="text-lg flex"><feather-icon
              icon="UserIcon"
              size="30"
              color="green"
              class="mr-50"/>Medio</p></b-dropdown-item>
            <b-dropdown-item><p class="text-lg flex"><feather-icon
              icon="UserIcon"
              size="30"
              color="blue"
              class="mr-50"/>Bajo</p></b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row class="justify-center" align="center">
          <b-col cols="4 d-none d-md-block">
              <p class="my-3 mx-4 text-lg flex"><feather-icon
              icon="AlertCircleIcon"
              size="30"
              class="mr-50"/>Nivel de criticidad</p>
          </b-col>
          <b-col cols="2 d-none d-md-block">
              <p class="my-3 mx-4 text-lg flex"><feather-icon
              icon="UserIcon"
              size="30"
              color="red"
              class="mr-50"/>Muy alto</p>
          </b-col>
          <b-col cols="2 d-none d-md-block">
              <p class="my-3 mx-4 text-lg flex"><feather-icon
              icon="UserIcon"
              size="30"
              color="orange"
              class="mr-50"/>Alto</p>
          </b-col>
          <b-col cols="2 d-none d-md-block">
              <p class="my-3 mx-4 text-lg flex"><feather-icon
              icon="UserIcon"
              size="30"
              color="green"
              class="mr-50"/>Medio</p>
          </b-col>
          <b-col cols="2 d-none d-md-block">
              <p class="my-3 mx-4 text-lg flex"><feather-icon
              icon="UserIcon"
              size="30"
              color="blue"
              class="mr-50"/>Bajo</p>
          </b-col>
      </b-row>
      <b-overlay
      :show="overlayList"
      opacity="1.0"
      spinner-small
      spinner-type="grow"
      spinner-variant="primary"
      @hidden="_onHidden()">
        <div v-if="view === 'list'"> 

          <b-overlay v-if="loadingScreen" :show="loadingScreen" opacity="1.0" spinner-small spinner-type="grow" spinner-variant="primary" class="spinner-block">
              <div class="d-flex align-items-end justify-content-end"></div>
          </b-overlay>

          <div class="scrollerH mb-1">
            <b-table
            hover
            striped
            show-empty
            class="position-relative mb-1"
            :items="business.prospects.data"
            :fields="tableColumns"
            primary-key="id"
            empty-text="Datos no Disponibles"
            v-if="business.prospects != null"
            >
            <template #cell(criticality)="data">
              <feather-icon
                icon="UserIcon"
                size="30"
                :color="_colorCriticality(data.item.criticality)"
                class="mr-50"/>
            </template>
            <template #cell(rut)="data">
              {{ data.item.customer.rut }} - {{ data.item.customer.rut_dv }}
            </template>
            <template #cell(rukan)="data">
              <b-badge v-if="data.item.customer.rukaneado != null" pill :variant="_getRukanStatusColor(data.item.customer.rukaneado.status)">
                {{ _rukanStatus(data.item.customer.rukaneado.status) }}
              </b-badge>
              <b-badge v-else pill variant="danger">
                Sin datos
              </b-badge>
            </template>
            <template #cell(preapproval)="data">
              <b-badge v-if="data.item.prospectapproval.length > 0" pill :variant="_getPreApprovalStatusColor(data.item.prospectapproval)">
                {{ _preApprovalStatus(data.item.prospectapproval) }}
              </b-badge>
              <b-badge v-else pill variant="danger">
                Sin datos
              </b-badge>
            </template>
            <template #cell(fecha)="data">
              {{ data.item.created_at | moment("DD-MM-YYYY") }}
            </template>
            <template #cell(actions)="data">
              <b-dropdown variant="link" no-caret
                :right="$store.state.appConfig.isRTL">
                <template #button-content>
                  <feather-icon size="16"
                    icon="MoreVerticalIcon"
                    class="align-middle text-body"/>
                </template>
                <b-dropdown-item @click.prevent="_handleSelected(data.item)" v-if="permissions_visible.edit_business">
                  <feather-icon icon="ArrowRightCircleIcon" />
                  <span class="align-middle ml-50">Detalles</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          </div>
          <b-pagination
            align="right"
            v-model="currentPage"
            hide-goto-end-buttons
            v-if="business.prospects != null"
            :total-rows="business.prospects.total"/>
        </div>
        <div v-if="view === 'card'"> 
          <b-overlay :show="overlayFormSecondPanel" opacity="1.0" spinner-small spinner-type="grow" spinner-variant="primary" class="spinner-width-f1">
            <b-row v-if="businesspertype.prospectspertypequery != null">
              <div class="scrollerH">
                  <div class="scrollerSlide" v-for="(stages, index) in pipelines.pipelines[0].pipelinestages" :key="index">
                    <div class="mx-2" :style="sheet">
                      <b-row>
                        <b-col class="my-1" vs-type="flex" vs-justify="flex-start" cols="12">
                          <h4 class="mx-1 font-weight-bolder">  
                            <b-row>
                              <b-col md="9" sm="9">
                                {{stages.name}}
                              </b-col>
                              <b-col md="3" sm="3">
                                <!-- contador -->
                                <span >
                                  <div v-if="businesspertype.prospectspertypequery != null">
                                    <div v-if="businesspertype.prospectspertypequery.paginate_totals != null">
                                      <div v-for="(totalspage, indexT) in businesspertype.prospectspertypequery.paginate_totals" :key="indexT"> 
                                        <div v-if="stages.id == totalspage.id"> 
                                          <div v-if="totalPerType[stages.id] != null" class='total_container_'> 
                                            {{totalPerType[stages.id]}} / {{totalspage.data}}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                                <!-- /contador -->
                              </b-col>
                            </b-row>

                          </h4>
                        </b-col>
                      </b-row>
                      <div class="scroller">
                        <div v-for="(stage, index) in businesspertype.prospectspertypequery.data" :key="index">
                          <div v-if="stages.id === stage.pipelinestage.id">
                            <a @click="_handleSelected(stage)">
                              <b-card actionable no-body>
                                <template #header>
                                  <h5 class="mb-n2 two-lines"><feather-icon :color="_colorCriticality(stage.criticality)" size="30" icon="UserIcon" class="mr-50"/>{{stage.customer.name}} {{stage.customer.last_name}}</h5>
                                </template>
                                <div class="ml-n1">
                                  <b-card-body>
                                    {{stage.preapproval}}
                                    <b-card-text class="businessCard"><feather-icon icon="TagIcon" class="mr-50"/>R.U.N.: {{stage.customer.rut}}-{{stage.customer.rut_dv}}</b-card-text>
                                    <b-card-text class="businessCard"><feather-icon icon="SmartphoneIcon" class="mr-50"/>Teléfono: {{stage.customer.phone}}</b-card-text>
                                    <b-card-text class="businessCard"><feather-icon icon="MailIcon" class="mr-50"/>Correo: {{stage.customer.email}}</b-card-text>
                                    <b-card-text class="businessCard"><feather-icon icon="BookmarkIcon" class="mr-50"/>Rukan:
                                    <b-badge v-if="stage.customer.rukaneado != null" pill :variant="_getRukanStatusColor(stage.customer.rukaneado.status)">
                                        {{ _rukanStatus(stage.customer.rukaneado.status) }}
                                    </b-badge>
                                    <b-badge v-else pill variant="danger">
                                      Sin datos
                                    </b-badge>
                                    </b-card-text>
                                    <b-card-text class="businessCard"><feather-icon icon="BookmarkIcon" class="mr-50"/>Pre aprobado:
                                    <b-badge v-if="stage.prospectapproval.length > 0" pill :variant="_getPreApprovalStatusColor(stage.prospectapproval)">
                                        {{ _preApprovalStatus(stage.prospectapproval) }}
                                    </b-badge>
                                    <b-badge v-else pill variant="danger">
                                      Sin datos
                                    </b-badge>
                                    </b-card-text>
                                    <b-card-text class="businessCard"><feather-icon icon="CalendarIcon" class="mr-50"/>Fecha: {{stage.created_at | moment("DD-MM-YYYY")}}</b-card-text>
                                  </b-card-body>
                                </div>
                              </b-card>
                            </a>
                          </div> 
                        </div><!-- vfor prospects --> 
                        <!-- ver mas -->
                        <div v-if="businesspertype.prospectspertypequery != null">
                          <div v-if="businesspertype.prospectspertypequery.paginate_totals != null">
                            <div v-for="(totalspage, indexT) in businesspertype.prospectspertypequery.paginate_totals" :key="indexT"> 
                              <div v-if="stages.id == totalspage.id"> 
                                <div v-if="totalPerType[stages.id] != null"> 
                                  <div v-if="totalspage.data > totalPerType[stages.id]">
                                    <center :totalreal="totalspage.data" :totalpertypeshowed="totalPerType[stages.id]"> 
                                      <b-overlay :show="overlayList" opacity="0.5" spinner-small spinner-variant="primary" class="d-inline-block">
                                        <b-button ref="button" size="sm" variant="primary" @click.prevent="fetchMoreProspects(stages.id,stages.id)" ><span class="align-middle">Ver más</span></b-button>
                                      </b-overlay>
                                    </center>
                                  </div> 
                                </div>  
                              </div>
                            </div> 
                          </div> 
                        </div> 
                        <!-- /ver mas -->

                      </div>
                    </div>
                  </div><!-- vfor stages -->
              </div> 
            </b-row>
            <b-row v-else>
              <b-col class="flex items-center justify-center flex-col">
                <p class="my-3 mx-4 text-lg flex" ><feather-icon icon="AlertCircleIcon" class="mr-50"/>No hay datos en este proyecto</p>
              </b-col>
            </b-row>
          </b-overlay>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex';
  import ToastificationContent from "@core/components/toastification/ToastificationContent"
  import { SpinnerPlugin } from 'bootstrap-vue'
  export default {
    components:{
      SpinnerPlugin
    },
    name: 'BusinessList',
    data(){
      return{
        permissions_visible:{
          show_business:false,
          download_business:false,
          edit_business:false,
        },
        overlayList: false,
        overlayForm: false,
        overlayFormSecondPanel: false,
        currentPage: 1,
        totalPage: 0,
        totalPerType:[],
        sheet:{
          backgroundColor:"#EFEFEF"
        },
        view: "list",
        businessSelected: [],
        projectSelected: "",
        tableColumns: [
          {key: 'criticality', sortable: false, label: 'Criticidad'},
          {key: 'customer.name', sortable: false, label: 'Nombre'},
          {key: 'rut', sortable: false, label: 'R.U.N.'},
          {key: 'customer.email', sortable: false, label: 'Email'},
          {key: 'rukan', sortable: false, label: 'Rukan'},
          {key: 'preapproval', sortable: false, label: 'Preaprobado'},
          {key: 'fecha', sortable: false, label: 'Fecha'},
          {key: 'actions', sortable: false, label: 'Acciones'},
        ],
        pipelineData: [],
        loadingScreen:false,
        firstLoaded:false,
        stopLoading:false,
        filtersForm: {
          general: null,
          project: null,
          start: null,
          end: null,
          page: 1,
          rukan: null,
          pre_approval: null,
        },
        general: null,
        rukanState: [
          {id: "true", name: "Rukaneado"},
          {id: "false", name: "Sin datos"},
        ],
        preApprovedState: [
          {id: "approved", name: "Aprobado"},
          {id: "pending", name: "Pendiente"},
          {id: "rejected", name: "Rechazado"},
        ],
      }
    },
    mounted(){  
      let self = this;
      this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible); 
      
      this.stopLoading = true;  
      if(this.filter_bussines.project != null && this.filter_bussines.project != undefined){
        this.filtersForm.project = this.filter_bussines.project;
      }
      if(this.filter_bussines.general != null && this.filter_bussines.general != undefined){
        this.filtersForm.general  = this.filter_bussines.general;
      }
      if(this.filter_bussines.start != null && this.filter_bussines.start != undefined){
        this.filtersForm.start = this.filter_bussines.start;
      }
      if(this.filter_bussines.end != null && this.filter_bussines.end != undefined){
        this.filtersForm.end = this.filter_bussines.end;
      }
      if(this.filter_bussines.page != null && this.filter_bussines.page != undefined){
        this.filtersForm.page = this.filter_bussines.page;
      }
      if(this.filter_bussines.rukan != null && this.filter_bussines.rukan != undefined){
        this.filtersForm.rukan = this.filter_bussines.rukan;
      }
      if(this.filter_bussines.pre_approval != null && this.filter_bussines.pre_approval != undefined){
        this.filtersForm.pre_approval = this.filter_bussines.pre_approval; 
      }
      if(this.filter_bussines_view != ""){
        this.view = this.filter_bussines_view;
      } 
      setTimeout(function(){ self.stopLoading = false; }, 100); 

      if(this.businesspertype.prospectspertypequery == undefined){ 
        this.loadingScreen = true; 
        this.filtersForm.page = this.currentPage;
        this._fetchPipelines();
        this._fetchBusiness(this.filtersForm);
        this._fetchBusinessPerType(this.filtersForm);
      }else{ 
        this._fetchBusiness(this.filtersForm);
        this.loadingScreen = false;
        this.recalculateTotals();
      }
    },
    beforeMount() {
      
    },
    computed: {
      ...mapState('business',['business', 'businesspertype','businesspertypetotals','pipelines','filter_bussines','filter_bussines_view']),
      ...mapGetters('profile',['projects']),
      windowWidth () { return this.$store.state.windowWidth },
    },
    watch:{
      view(val) {
        this.set_filters_business_view(val);
      },
      filtersForm: {
        handler(filter){ 
          if(!this.stopLoading){
              this._fetchBusiness(filter)  
              this._fetchBusinessPerType(filter)
          }
        },
        deep: true
      },
      currentPage (val) {
        if(!this.stopLoading){
          let self = this;
          this.stopLoading = true; 
          this.filtersForm.page = this.currentPage
          this._fetchBusiness(this.filtersForm) 
          setTimeout(function(){ self.stopLoading = false; }, 500);
        }
      },
    },
    methods: {
      ...mapActions('business',['fetchPipelines', 'fetchBusiness','fetchBusinessPerTypeNoAsync','fetchBusinessPerTypeNoAsyncClean','fetchBusinessPerType','exportBusiness','set_filters_business','set_filters_business_view']),
      async _fetchBusiness (filter) { 
        this.set_filters_business(filter);
        this.overlayList = true
        let self = this;
        await this.fetchBusiness(filter)
        .then(res => { 
          self.loadingScreen = false;
        })
        .finally(() => {
          this.overlayList = false
        })
      },

      async _fetchBusinessPerType (filter) { 
        this.firstLoaded = true;
        this.overlayFormSecondPanel = true;
        await this.fetchBusinessPerType(filter)
        .then(res => {
          this.recalculateTotals();
        })
        .finally(() => {
          this.overlayFormSecondPanel = false
        }) 
      },
      fetchFromFilter() { 
        let self = this;
        this.overlayFormSecondPanel = true;
        self.businesspertype.prospectspertypequery = [];
        this.fetchBusinessPerTypeNoAsyncClean(this.filtersForm)
        .then(res => { 
          self.recalculateTotals();
        })
        .finally(() => {
          self.overlayFormSecondPanel = false
        })
      },
      fetchMoreProspects (type,totalpageIn) {
        let self = this;
        this.filtersForm.subquery = type;
        this.filtersForm.subpage = this.totalPerType[totalpageIn];
        this.overlayFormSecondPanel = true; 
        this.fetchBusinessPerTypeNoAsync(this.filtersForm)
        .then(res => {
          self.recalculateTotals();
        })
        .finally(() => {
          self.overlayFormSecondPanel = false
        })
      }, 
      recalculateTotals(){    
        if(this.pipelines.pipelines){
          this.totalPerType = []; 
          this.pipelines.pipelines[0].pipelinestages.forEach(pipeElement => { 
            this.totalPerType[pipeElement.id] = 0;
            if(this.businesspertype.prospectspertypequery)
            if(this.businesspertype.prospectspertypequery.data){  
              this.businesspertype.prospectspertypequery.data.forEach(BusinessElement => {  
                if(BusinessElement.pipelinestage.id == pipeElement.id){
                  this.totalPerType[pipeElement.id]++;
                }
              }); 
            }
          });
        }  
      },

      async _fetchPipelines () {
        await this.fetchPipelines()
      },
      _rukanStatus(status){
        return status ? 'Rukaneado' : 'Sin datos'
      },
      async _exportBusiness() {
        this.overlayForm = true
        await this.exportBusiness(this.filtersForm)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se ha enviado un correo con el archivo',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al exportar el archivo',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.overlayForm = false
        })
      },
      _handleSelected(prospect) {
        this.$router.push({ name: 'business-details', params: {id: prospect.id, prospect: prospect}})
      },
      _colorCriticality(criticality){
        switch (criticality) {
          case 'very_high':
            return 'red'
          case 'high':
            return 'orange'
          case 'medium':
            return 'green'
          case 'low':
            return 'blue'
        }
      },
      _getRukanStatusColor(status){
        return status ? 'light-success' : 'light-danger'
      },
      _getPreApprovalStatusColor(status){
        let aux = false
        status.forEach(element => {
            if(element.preapproval.status === 'approved'){
              aux = true
            }
        })
        return aux === true ? 'light-success' : 'light-warning'
      },
      _preApprovalStatus(status){
        let aux = false
        status.forEach(element => {
            if(element.preapproval.status === 'approved'){
              aux = true
            }
        })
        return aux === true ? 'Aprobado' : 'Pendiente'
      },
      _onHidden() {
        //this.$refs.button.focus()
      },
    },
  }
</script>
<style>
  .spinner-width-f1 .b-overlay.position-absolute{  
      left: -14px !important;
      width: 102% !important;
    }
</style>
<style scoped>
    .two-lines{
      white-space: initial;
    }
    .flex {
        display: flex;
        align-items: center;
    }
    a {
        color: gray;
    }
    .scroller {
        height: 500px;
        width: 100%;
        overflow-y: scroll;
        padding: 10px 10px 10px;
    }
    .scrollerH {
        width: 100%;
        height: auto;
        overflow-x: scroll;
        white-space:nowrap;
    }
    .scrollerSlide {
        width: 400px;
        display: inline-block;
    }
     .card-text{
        font-size: 13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .spinner-block{ 
      width: 100%;
      height: 300px;
    }
</style>
<template>
    <div id="business">
        <b-row>
            <b-col cols="12">
                <business-list></business-list>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import BusinessList from './components/BusinessList'

export default{
    name: 'BusinessView',
    components: {BusinessList}
}
</script>
